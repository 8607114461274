import { useRouter } from "next/router";
import Container from "../components/container";
import ErrorPage from "next/error";
import PageTitle from "../components/page-title";
import Layout from "../components/layout";
import { getHomepage, getNavigation } from "../lib/api";
import Head from "next/head";
import { CMS_NAME } from "../lib/constants";
import CMSContentModuleRenderer from "../components/contentful-content-modul-render";
import CMSStageModuleRenderer from "../components/contentful-stage-modul-render";

export default function Index({ page, navigation, preview }) {
  const router = useRouter();

  if (!router.isFallback && !page) {
    return <ErrorPage statusCode={404} />;
  }

  return (
    <>
      <Layout
        preview={preview}
        header={page?.header}
        footer={page?.footer}
        navigation={page?.navigation}
        navigationData={navigation}
        seo={page.seo}
      >
        <Container>
          {router.isFallback ? (
            <PageTitle>Loading…</PageTitle>
          ) : (
            <>
              <article>
                <Head>
                  <title>
                    {page.title}
                  </title>
                  <meta
                    name="description"
                    content={
                      page.seo.description ||
                      `A statically generated blog example using Next.js.`
                    }
                  />
                </Head>
                {page.stage && CMSStageModuleRenderer(page.stage)}
                {page.content.map((e, i) => CMSContentModuleRenderer(e, i))}
              </article>
            </>
          )}
        </Container>
      </Layout>
    </>
  );
}

export async function getStaticProps({ preview = false }) {
  const data = await getHomepage(preview);
  const navigation = await getNavigation();
  return {
    props: {
      preview,
      page: data?.page ?? null,
      navigation: navigation ?? null,
    },
  };
}
